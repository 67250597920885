$break-small: 550px;
$break-large: 1024px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) { @content; }
  }
}

body {
  font-family: 'Oxygen', serif;
  font-size: 15px;
  line-height: 23px;
  -webkit-text-size-adjust: none;
}

a {
  font-size: 15px;
  text-decoration: none;
  color: #dd0229;

  &:hover {
    text-decoration: underline;
  }
}

em {
  font-style: italic;
}

strong {
  font-style: normal;
  font-weight: bold;
}

h1, h2, h3 {
  font-weight: bold;
  margin: 25px 0 10px 0;
}

h1 {
  font-size: 31px;
  line-height: 44px;
}

h2 {
  font-size: 22px;
  line-height: 23px;
}

h3 {
  font-size: 18px;
  line-height: 23px;
}

header.site-header {
  @include respond-to(handhelds) {
    width: 90%;
    min-width: 270px;
    padding: 0 15px;
  }
  @include respond-to(medium-screens) {
    width: 90%;
    max-width: 640px;
    padding: 0 5%;
  }
  @include respond-to(wide-screens) {
    width: 640px;
  }

  margin: 0 auto;
  position: relative;
  font-family: 'Oxygen', serif;
  font-weight: 300;
  height: 100px;

  #logo {
    position: absolute;
    top: 0;

    @include respond-to(handhelds) {
      height: 50px;
      width: 50px;
    }
  }

  nav {
    position: absolute;
    right: 0;

    @include respond-to(handhelds) {
      right: 15px;
      width: 200px;
    }
    @include respond-to(medium-screens) {
      right: 5%;
    }

    ul li {
      float: left;
      margin: 0 0 0 43px;

      @include respond-to(handhelds) {
        margin: 0 0 0 14px;
        width: 75px;
        text-align: center;
      }

      a {
        display: block;
        padding: 15px 2px 0 2px;
        margin-top: 6px;
        text-decoration: none;
        color: #A4A4A4;
        text-transform: uppercase;
        font-family: 'Oswald', serif;
        font-size: 1.3em;

        @include respond-to(handhelds) {
          margin-top: 0;
        }

        &.active, &:hover {
          color: #000000;
          border-top: 6px solid #000000;
          margin-top: 0;

          @include respond-to(handhelds) {
            border-top: 0;
          }
        }
      }
    }
  }
}

.page-content {
  margin: 55px auto 0 auto;

  @include respond-to(handhelds) {
    width: 90%;
    padding: 0 15px;
    margin-top: 0;
  }
  @include respond-to(medium-screens) {
    width: 90%;
    max-width: 640px;
    padding: 0 5%;
  }
  @include respond-to(wide-screens) {
    width: 640px;
  }

  h1 {
    font-family: 'Oswald', serif;
    font-weight: 300;
  }

  p {
    line-height: 28px;
    margin-bottom: 15px;
  }
}

#about-pic {
  border-radius: 50%;
  float: right;
  margin: 5px 0 20px 20px;

  @include respond-to(handhelds) {
    float: none;
    display: block;
    margin: 15px auto;
  }
}

p.articles-archive-disclaimer {
  color: #777777;
  margin: 10px 0 30px;
  line-height: 23px;
}

.articles-archive {
  h2 {
    font-family: 'Oswald', serif;
    font-weight: 300;
    margin: 0;

    a {
      color: #000000;
      font-size: 31px;
      line-height: 44px;
    }
  }

  li {
    margin-bottom: 26px;
    padding: 0 15px 20px 15px;
    border-bottom: 1px solid #000000;

    .date {
      color: #A4A4A4;
    }
  }
}

article.post {
  margin-bottom: 70px;
  position: relative;

  @include respond-to(handhelds) {
    margin-bottom: 120px;
  }

  #box-about {
    width: 150px;
    position: absolute;
    text-align: right;
    font-family: 'Oswald', serif;
    font-weight: 300;
    line-height: 1.8em;

    @include respond-to(handhelds) {
      width: 250px;
      right: 0;
      bottom: -60px;
      padding-right: 56px;
    }
    @include respond-to(medium-screens) {
      position: relative;
      left: 0;
      float: right;
      padding-right: 56px;
    }
    @include respond-to(wide-screens) {
      top: 5px;
      left: -180px;
    }

    img {
      @include respond-to(handhelds) {
        position: absolute;
        right: 0;
        top: 2px;
      }
      @include respond-to(medium-screens) {
        position: absolute;
        right: 0;
      }

      border-radius: 50%;
    }

    .name {
      font-size: 22px;
      line-height: 27px;
      margin: 0;
    }

    ul.facts {
      list-style: none outside none;

      @include respond-to(handhelds) {
        position: absolute;
        right: 56px;
      }

      li {
        color: #A4A4A4;
        font-size: 16px;
        line-height: 23px;

        @include respond-to(handhelds) {
          float: left;
          margin-left: 8px;
        }

        a {
          color: #A4A4A4;
          font-size: 1.0em;
        }
      }
    }
  }

  header {
    margin-bottom: 10px;

    h1 {
      margin-bottom: 0;
    }

    .date {
      color: #A4A4A4;
    }
  }

  p, blockquote, ul, ol, div {
    line-height: 1.9em;
    margin: 0 0 15px 0;

    &:not(.highlight) > code {
      background-color: #EEEEEE;
      border: 1px solid #999999;
      border-radius: 5px;
      font-family: monospace;
      padding: 3px 5px;
      margin: 0 3px;
    }
  }

  ul, ol {
    list-style: disc inside none;
    padding-left: 20px;
  }

  blockquote {
    margin: 20px 0;
    padding: 0 0 0 20px;
    border-left: 4px solid #EEEEEE;
  }

  div.highlight {
    background-color: #EEEEEE;
    border-radius: 10px;
    padding: 7px 15px;
    font-family: monospace;
    font-size: 0.9em;
    line-height: 1.5em;

    pre {
      overflow-x: auto;
    }
  }
}


footer.site-footer {
  margin: 50px auto 30px;
  text-align: center;

  a {
    color: #A4A4A4;
    font-size: 0.9em;
  }
}

.clear:before,
.clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}
